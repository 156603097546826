import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bootcampDates } from "../../constants/bootcamp/bootcampData";
import {
  handleSelectCohort,
  handleDataScienceCohort,
  handleStoreBootcampDate,
  handleIntroToDataAnalyticsCohort,
} from "../../redux/reducers/bootcampSlice";

// @components
import PromoCode from "../../utils/PromoCode/PromoCode";
import { SelectionMarker } from "../../utils/SelectionMarker";
import {
  formatDateTwoDaysBack,
  formatBootcampKickOffDate,
} from "../../utils/CountdownTimer/CountdownTimer";

const BootcampSignupDetails = ({
  amount,
  discount,
  discountArr,
  paymentType,
  bootcampType,
  discountArray,
  applicationFee,
  bootcampSignUp,
}) => {
  const dispatch = useDispatch();
  const selectedCohort = useSelector(
    (state) => state.bootcamp.selectCohort
  );
  const dataScienceCohort = useSelector(
    (state) => state.bootcamp.dataScienceCohort
  );
  const introToDataAnalyticsCohort = useSelector(
    (state) => state.bootcamp.introToDataAnalyticsCohort
  );
  const introToDataAnalyticsSelected = useSelector(
    (state) => state.bootcamp.introToDataAnalyticsSelected
  );
  const dataScienceSelected = useSelector(
    (state) => state.bootcamp.dataScienceSelected
  );

  const bootcampStartDate = useSelector(
    (state) => state.bootcamp.bootcampStartDate
  );

  const storeBootcampDetails = (
    id,
    date,
    dataScienceCohort,
    introToDataAnalyticsCohort
  ) => {
    dispatch(handleSelectCohort(id));
    dispatch(handleStoreBootcampDate(date));
    dispatch(handleDataScienceCohort(dataScienceCohort));
    dispatch(
      handleIntroToDataAnalyticsCohort(introToDataAnalyticsCohort)
    );
  };

  useEffect(() => {
    dispatch(handleSelectCohort(19));
    dispatch(handleDataScienceCohort(9));
    dispatch(handleStoreBootcampDate("2025-2-3"));
    dispatch(handleIntroToDataAnalyticsCohort(4));
  }, [dispatch]);

  const deadlineDate = formatDateTwoDaysBack(bootcampStartDate);
  const kickOffDate = formatBootcampKickOffDate(bootcampStartDate);

  return (
    <div className="my-9">
      <div className="">
        {!bootcampSignUp && (
          <div className="mb-6">
            <h4 className="font-bold">Bootcamp cohort dates</h4>
            <p className="text-sm font-light mt-2">
              Select what cohort you would like to join.
            </p>
          </div>
        )}
        <div className="border-b pb-6 xs:w-full">
          {!bootcampSignUp && (
            <div className="flex flex-col gap-3 w-full">
              {bootcampDates.map((date, index) => {
                return (
                  <Fragment key={index}>
                    {dataScienceSelected ? (
                      date.dataScience.cohort !== "Cohort 0.0" && (
                        <div
                          onClick={() =>
                            date.isDisabled === false
                              ? storeBootcampDetails(
                                  date.bootcampId,
                                  date.dateToParse,
                                  date.dataScience.bootcampId,
                                  date.introToDataAnalytics.bootcampId
                                )
                              : null
                          }
                          key={index}
                          className={`${
                            date.dataScience.bootcampId ===
                              dataScienceCohort &&
                            "relative border border-[#f1f3f4]"
                          } flex flex-row items-center justify-between border border-[#f1f3f4] bg-[#f1f3f4] rounded p-4 ${
                            date.isDisabled === true
                              ? "cursor-not-allowed text-[#9e9ea7] bg-[#f3f3f4] border-[#f3f3f4]"
                              : "cursor-pointer"
                          }`}
                        >
                          <span className="text-xs">
                            {date.dataScience.cohort}
                          </span>
                          <span className="text-xs font-medium">
                            {date.date}
                          </span>
                          {date.dataScience.bootcampId ===
                            dataScienceCohort && <SelectionMarker />}
                        </div>
                      )
                    ) : introToDataAnalyticsSelected ? (
                      date.introToDataAnalytics.cohort !==
                        "Cohort 0.0" && (
                        <div
                          onClick={() =>
                            date.isDisabled === false
                              ? storeBootcampDetails(
                                  date.bootcampId,
                                  date.dateToParse,
                                  date.dataScience.bootcampId,
                                  date.introToDataAnalytics.bootcampId
                                )
                              : null
                          }
                          key={index}
                          className={`${
                            date.introToDataAnalytics.bootcampId ===
                              introToDataAnalyticsCohort &&
                            "relative border border-[#f1f3f4]"
                          } flex flex-row items-center justify-between border border-[#f1f3f4] bg-[#f1f3f4] rounded p-4 ${
                            date.isDisabled === true
                              ? "cursor-not-allowed text-[#9e9ea7] bg-[#f3f3f4] border-[#f3f3f4]"
                              : "cursor-pointer"
                          }`}
                        >
                          <span className="text-xs">
                            {date.introToDataAnalytics.cohort}
                          </span>
                          <span className="text-xs font-medium">
                            {date.date}
                          </span>
                          {date.introToDataAnalytics.bootcampId ===
                            introToDataAnalyticsCohort && (
                            <SelectionMarker />
                          )}
                        </div>
                      )
                    ) : (
                      <div
                        onClick={() =>
                          date.isDisabled === false
                            ? storeBootcampDetails(
                                date.bootcampId,
                                date.dateToParse,
                                date.dataScience.bootcampId,
                                date.introToDataAnalytics.bootcampId
                              )
                            : null
                        }
                        key={index}
                        className={`${
                          date.bootcampId === selectedCohort &&
                          "relative border border-[#f1f3f4]"
                        } flex flex-row items-center justify-between border border-[#f1f3f4] bg-[#f1f3f4] rounded p-4 ${
                          date.isDisabled === true
                            ? "cursor-not-allowed text-[#9e9ea7] bg-[#f3f3f4] border-[#f3f3f4]"
                            : "cursor-pointer"
                        }`}
                      >
                        <span className="text-xs">
                          {dataScienceSelected
                            ? date.dataScience.cohort
                            : date.cohort}
                        </span>
                        <span className="text-xs font-medium">
                          {date.date}
                        </span>
                        {date.bootcampId === selectedCohort && (
                          <SelectionMarker />
                        )}
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          )}
          {!applicationFee && (
            <>
              {" "}
              <div className="text-right text-xs mt-4">
                Discount deadline:{" "}
                <span className="text-[#FF8C00] font-normal">
                  {deadlineDate} 11:59 PM
                </span>
              </div>
              <div className="text-right text-xs mt-2">
                Bootcamp officially kicks off on{" "}
                <span className="underline font-medium">
                  {kickOffDate} by 8 PM WAT{" "}
                </span>
              </div>
            </>
          )}
        </div>

        {paymentType.oneTimePayment && !applicationFee && (
          <div className="mt-9 border-b pb-6 xs:w-full">
            <div className="">
              <PromoCode
                amount={amount}
                discount={discount}
                discountArr={discountArr}
                bootcampType={bootcampType}
                discountArray={discountArray}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BootcampSignupDetails;
