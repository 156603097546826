import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Loader from "../utils/Loader/Loader";

const BootcampPaymentPrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const [customClaims, setCustomClaims] = useState({
    isLoading: true,
    isPaidSubscriber: false,
  });

  useEffect(() => {
    (async () => {
      try {
        const idTokenResult = await firebase
          .auth()
          .currentUser?.getIdTokenResult(true);

        const isPaidSubscriber =
          idTokenResult?.claims?.isBootcamper ||
          idTokenResult?.claims?.isApplicationFee;
        setCustomClaims({ isLoading: false, isPaidSubscriber });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentUser]);

  if (customClaims.isLoading) return <Loader />;
  else if (!customClaims.isPaidSubscriber)
    return <Navigate to="/bootcamp/paths" />;
  else return children;
};

export default BootcampPaymentPrivateRoute;
