// company logos
import CU from "../../assets/cu.png";
import EgbinPower from "../../assets/egbin-power.png";
import LBS from "../../assets/lbschool.png";
import MKopa from "../../assets/m-kopa.png";
import Shell from "../../assets/shell.png";
import Softcom from "../../assets/softcom.png";
import TradeDepot from "../../assets/trade-depot.png";
import UI from "../../assets/uofibadan.png";
import Dangote from "../../assets/dangote.jpg";
import Deloitte from "../../assets/deloitte.png";

export const structure = [
  {
    id: 1,
    text: `Grow your data analytics skills practically by leaning from industry experts`,
  },
  {
    id: 2,
    text: `3 months of structured learning`,
  },
  {
    id: 3,
    text: `Assessment and quizzes to check your knowledge`,
  },
  {
    id: 4,
    text: `Over 30 hours of asynchronous online learning delivered by expert, certified instructors `,
  },
  {
    id: 5,
    text: `Study in groups, build portfolio projects, and get access to live support via Discord `,
  },
  {
    id: 6,
    text: `Additional hours of synchronous live mentor sessions`,
  },
  {
    id: 7,
    text: `Earn certification`,
  },
  {
    id: 8,
    text: `Career growth session, job preparation support, and post-bootcamp support for 6 months`,
  },
];

export const videos = [
  {
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_oriola_timilehin.mp4`,
    student: `Timilehin`,
  },
  {
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_omowunmi.mp4`,
    student: `Omowunmi`,
  },
  {
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_dolapo_04_2023.mp4`,
    student: `Dolapo`,
  },
  {
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_fisayo_and_gbemisola.mp4`,
    student: `Gbemisola & Fisayo`,
  },
  {
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_marytheresa_04_2023.mp4`,
    student: `Marytheresa`,
  },
  {
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_steve_04_2023.mp4`,
    student: `Steve`,
  },
];

export const logos = [
  {
    logo: CU,
  },
  {
    logo: EgbinPower,
  },
  {
    logo: LBS,
  },
  {
    logo: MKopa,
  },
  {
    logo: Shell,
  },
  {
    logo: Softcom,
  },
  {
    logo: TradeDepot,
  },
  {
    logo: UI,
  },
  {
    logo: Dangote,
  },
  {
    logo: Deloitte,
  },
];

export const courseLogos = [
  {
    title: `Excel`,
    image: `https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/excel_logo_new.png`,
  },
  {
    title: `Power BI`,
    image: `https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/power_bi_logo_new.png`,
  },
  {
    title: `Tableau`,
    image: `https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/tableau_logo.png`,
  },
  {
    title: `SQL`,
    image: `https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/Postgresql_elephant.svg.png`,
  },
  {
    title: `Google Sheets`,
    image: `https://storage.googleapis.com/resagratia-webapp/misc/sheets.svg`,
  },
];

export const bootcampDates = [
  {
    bootcampId: 18,
    date: `6 January 2025`,
    cohort: `Cohort 20.0`,
    dateToParse: `2025-1-6`,
    isDisabled: true,
    dataScience: {
      bootcampId: 8,
      cohort: `Cohort 8.0`,
    },
    introToDataAnalytics: {
      bootcampId: 3,
      cohort: `Cohort 3.0`,
    },
  },
  {
    bootcampId: 19,
    date: `3 February 2025`,
    cohort: `Cohort 21.0`,
    dateToParse: `2025-2-3`,
    isDisabled: false,
    dataScience: {
      bootcampId: 9,
      cohort: `Cohort 9.0`,
    },
    introToDataAnalytics: {
      bootcampId: 4,
      cohort: `Cohort 4.0`,
    },
  },
];

export const authors = [
  {
    imageUrl: `https://storage.googleapis.com/resagratia-webapp/profile_photo/ahmed_oyelowo_image.jpg`,
    authorName: `Ahmed Oyelowo`,
  },
  {
    imageUrl: `https://storage.googleapis.com/resagratia-webapp/profile_photo/benny_ifeanyi_image.jpg`,
    authorName: `Benny Ifeanyi`,
  },
  {
    imageUrl: `https://storage.googleapis.com/resagratia-webapp/profile_photo/geovanni_ubah_blue.png`,
    authorName: `Geovanni Ubah`,
  },
  {
    imageUrl: `https://storage.googleapis.com/resagratia-webapp/profile_photo/john_analoh.jpg`,
    authorName: `John Analoh`,
  },
  {
    imageUrl: `https://storage.googleapis.com/resagratia-webapp/profile_photo/tobi_williams_blue.png`,
    authorName: `Tobi Williams`,
  },
  {
    imageUrl: `https://storage.googleapis.com/resagratia-webapp/profile_photo/gbemisola_odedina_photo.png`,
    authorName: `Gbemisola Odedina`,
  },
  {
    imageUrl: `https://storage.googleapis.com/resagratia-webapp/profile_photo/bayode_enoch_profile.jpeg`,
    authorName: `Bayode Enoch`,
  },
  {
    imageUrl: `https://storage.googleapis.com/resagratia-webapp/profile_photo/taiwo_adegite_headshot.jpg`,
    authorName: `Taiwo Adegite`,
  },
  {
    imageUrl: `https://storage.googleapis.com/resagratia-webapp/profile_photo/samuel_taiwo_headshot.jpg`,
    authorName: `Samuel Taiwo`,
  },
  {
    imageUrl: `https://storage.googleapis.com/resagratia-webapp/profile_photo/ifunanya_ubah.jpg`,
    authorName: `Ifunanya Ubah`,
  },
];

export const careerPaths = [
  {
    careerPath: `Operations Analyst`,
  },
  {
    careerPath: `Business Intelligence Analyst`,
  },
  {
    careerPath: `Financial Analyst`,
  },
  {
    careerPath: `Insights Assosciate`,
  },
  {
    careerPath: `Customer Insights Analyst`,
  },
  {
    careerPath: `Healthcare Data Analyst`,
  },
];

export const bootcampFeatures = [
  {
    id: 1,
    desc: `Lifetime access to our learning platform`,
  },
  {
    id: 2,
    desc: `Lifetime access to our community on Discord`,
  },
  {
    id: 3,
    desc: `Lifetime access to fresh, daily data and tech jobs`,
  },
  {
    id: 4,
    desc: `Data literacy courses`,
  },
  {
    id: 6,
    desc: `Weekly mentorship, quizzes and performance reports`,
  },
];
