import { v4 as uuidv4 } from "uuid";
import {
  getQuizScores,
  setCompletedCourses,
  getCompletedLessons,
  getFromMongoOrSessionStrorage,
} from "../../utils/GetCourseDetails";
import { useEffect, useRef, useState } from "react";
import { db } from "../../firebase/firebase";
import { useOutletContext } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Skeleton, Typography } from "@mui/material";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import { getFunctions, httpsCallable } from "firebase/functions";
import { collection, getDocs, query, where } from "firebase/firestore";
import useScrollToTop from "../../hooks/useScrollToTop";

const UserAchievements = () => {
  useScrollToTop();
  const functions = getFunctions();
  const { currentUser } = useAuth();
  const componentMounted = useRef(true);
  const { achievements } = useOutletContext();
  const [courseData, setCourseData] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [coursesCompleted, setCoursesCompleted] = useState(null);

  useEffect(() => {
    if (!componentMounted.current) return;

    (async () => {
      try {
        const completedCourses = [];
        const { quizScores } = await getQuizScores(currentUser.uid);
        const { completedLessons } = await getCompletedLessons(
          currentUser.email
        );
        const { courseDocs } = await getFromMongoOrSessionStrorage();

        await setCompletedCourses(
          courseDocs,
          quizScores,
          setCourseData,
          completedLessons,
          completedCourses,
          currentUser.uid
        );
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      setCourseData(null)
      setDownloadUrl(null)
      setCoursesCompleted(null)
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!courseData || !componentMounted.current) return;
    getCompletedCourses();
  }, [courseData]);

  useEffect(() => {
    if (!coursesCompleted || !componentMounted.current) return;

    (async () => {
      try {
        const getCourseCertificateDownloadUrl = httpsCallable(
          functions,
          "getCourseCertificateDownloadUrl"
        );

        const downloadUrls = await Promise.all(
          coursesCompleted.map(async (course) => {
            const userQuery = query(
              collection(db, "courseCertificate"),
              where("userId", "==", currentUser?.uid)
            );

            const querySnapshot = await getDocs(userQuery);
            const certs = [];

            querySnapshot.forEach((doc) => {
              const userCerts = doc.data().certs;
              certs.push(...userCerts);
            });

            const existingCertificate = certs.find(
              (cert) => cert.courseName === course.courseName
            );

            if (existingCertificate) {
              return {
                courseName: course.courseName,
                downloadUrl: existingCertificate.certificateURL,
              };
            } else {
              const newUUID = uuidv4();
              const todayDate = formatDate();
              const currentDateTime = getCurrentDateTime();
              const courseDetails = {
                courseId: newUUID,
                dateIssued: todayDate,
                userId: currentUser.uid,
                userEmail: currentUser.email,
                courseName: course.courseName,
                learnerName: achievements.fullName,
                generated_timestamp: currentDateTime,
                courseDuration: course.courseDuration,
              };
              
              const downloadUrlResponse = await getCourseCertificateDownloadUrl(
                courseDetails
              );

              return {
                courseName: course.courseName,
                downloadUrl: downloadUrlResponse.data,
              };
            }
          })
        );
        if (!componentMounted.current) return;
        setDownloadUrl(downloadUrls);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    })();
  }, [coursesCompleted]);

  function getCompletedCourses() {
    const completedCourseNames = courseData
      .filter((course) => {
        if (course.courseCompleted) {
          return course;
        }
      })
      .map((course) => ({
        courseName: course.courseName,
        courseDuration: course.courseDuration,
      }));
    setCoursesCompleted(completedCourseNames);
  }

  const url = (courseName) =>
    downloadUrl
      .filter((url) => url.courseName === courseName.courseName)
      .map((url) => url.downloadUrl)[0];

  return (
    <section className="flex flex-col gap-3 py-1">
      <MetaTags
        path={`profile`}
        title={`Resagratia | Achievements`}
        desc={`Download your course certificate.`}
      />
      <div className="">
        <h4 className="text-3xl leading-loose mb-4">
          Performance and Achievements
        </h4>
        <h3 className="text-[#11111180] font-semibold">Courses Completed</h3>
        <ol className="list-auto">
          {!coursesCompleted && (
            <>
              {loaderWidth.map((width, index) => (
                <LoadingCertSkeleton
                  key={width.width}
                  width={width.width}
                  index={index}
                />
              ))}
            </>
          )}
          {coursesCompleted &&
            coursesCompleted.map((courseName, index) => {
              return (
                <li
                  key={courseName.courseName}
                  className="max-w-[830px] flex gap-2 py-3 items-baseline"
                >
                  <div>{index + 1}.</div>
                  <div className="font-normal flex flex-wrap justify-between gap-2 max-[800px]:items-start items-center max-[800px]:flex-col flex-row flex-1">
                    <span>{courseName.courseName}</span>{" "}
                    <a
                      target="_blank"
                      href={`${downloadUrl ? url(courseName) : "#"}`}
                      className={`border px-3 py-1 w-44 text-center rounded-md ${
                        !downloadUrl
                          ? "cursor-not-allowed text-[#11111180] border-[]text-[#11111180]"
                          : "text-black border-black"
                      }`}
                    >
                      {`${
                        downloadUrl ? "Download certificate" : "Generating link"
                      }`}
                    </a>
                  </div>
                </li>
              );
            })}
          {coursesCompleted && coursesCompleted?.length === 0 && (
            <p className="text-center mt-8">
              Oops! No course has been completed yet.
            </p>
          )}
        </ol>
      </div>
    </section>
  );
};

const loaderWidth = [
  { width: "max-w-[320px]" },
  { width: "max-w-[340px]" },
  { width: "max-w-[180px]" },
];

const LoadingCertSkeleton = ({ width, index }) => (
  <li className="max-w-[830px] flex gap-2 py-3 items-baseline">
    <div>{index + 1}.</div>
    <div className="font-normal flex flex-wrap justify-between gap-2 max-[800px]:items-start items-center max-[800px]:flex-col flex-row flex-1">
      <Typography component="div" variant="h4" className={`${width} w-full`}>
        <Skeleton animation="wave" />
      </Typography>
      <Typography component="div" variant="h4" className="max-w-[176px] w-full">
        <Skeleton animation="wave" />
      </Typography>
    </div>
  </li>
);

function formatDate() {
  const options = { day: "numeric", month: "short", year: "numeric" };
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", options);
  return formattedDate;
}

function getCurrentDateTime() {
  const today = new Date();
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDateTime = today.toLocaleDateString("en-US", options);
  return formattedDateTime;
}

export default UserAchievements;
